.LandingBox {
	width: 100%;
	height:100vh;
	background: linear-gradient(
		90deg,
		rgb(36, 72, 102) 3%,
		rgba(70, 130, 180, 1) 20%,
		rgb(174, 205, 230) 50%,
		rgba(70, 130, 180, 1) 80%,
		rgb(36, 72, 102) 97%
	);

	/* z-index: 100; */
	position: absolute;
	top: 0;
	left: 0;

	/* opacity: 1; */
}

.LandingBox img {
	/* height: 100vh; */
	height: calc(var(--vh, 1vh) * 100 - 60px);
	width: auto;
	/* object-fit: cover; */
	position: absolute;
	max-width: 100vw;
	margin: auto;
	left: 0;
	right: 0;
	top: 50px;
	z-index: 1;
}
.welcomeMessage {
	/* margin: auto; */
	/* text-align: left; */
	font-size: 2em;
	/* padding: 28vh 10px 10px 50px; */
	width:80vw;
	position: relative;
	left: 15vw;
	top: 40vh;
	z-index: 1;
	color: white;
	text-shadow: 3px 2px rgba(62, 80, 91, 0.9);
}
.linkContainer {
	float: right;
	zoom: 1.5;
	z-index: 1;
	display: grid;
	margin-right: 10px;
}
.linkContainer a {
	/* border: gray 1px solid; */
	box-shadow: 2px 2px rgba(62, 80, 91, 0.9);
	margin: 2px 2px 2px 2px;
	background: rgba(70, 130, 180, 0.7);
	font-size: 0.9em;
	text-shadow: 2px 2px rgba(62, 80, 91, 0.9);
	/* border-radius: 5px; */
}
.NavIcons {
	margin: 0;
	padding: 0;
}

.NavIcons a {
	float: right;
	padding: 12px;
	color: white;
	transition: 0.5s;
	font-size: 0.8em;

	/* border-radius: 5px; */
	/* border: black 1px solid; */
}

.NavIcons a:hover {
	background-color: white;
	color: black;
	font-size: 150%;
	transition: 0.5s;
}
.Loader {
	position: absolute;
	top: 0px;
	/* display: block; */
	margin-left: auto;
	margin-right: auto;
	width: 100%;
	height: 100%;
	text-align: center;
	background: rgb(36, 72, 102);
	vertical-align: middle;
}
.Loader h1 {
	padding: 50px;
}

@media (max-width: 700px) {
	.welcomeMessage {
		font-size: 1.5em;
	}
}
@media (hover: none) {
	.NavIcons a:hover {
		color: white;
		background: rgba(70, 130, 180, 0.7);
		font-size: 0.8em;
		transition: none;
	}
	.welcomeMessage {
		text-align: center;
		/* padding: 40vh 10px 10px 10px; */
		left:10vw;
	}
}
