.Projects {
    width: 100%;
    min-height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-flow: row wrap;
    padding-top: 50px;
    margin: auto;
    	background: linear-gradient(
		90deg,
		rgb(36, 72, 102) 0%,
		rgba(70, 130, 180, 1) 20%,
		rgb(174, 205, 230) 50%,
		rgba(70, 130, 180, 1) 80%,
		rgb(36, 72, 102) 100%
    );
    /* background-position: 0; */
    /* background-attachment: fixed; */
}

@media(hover:none){
    .Projects{
        padding-bottom: 60px;
    }
}
