.AboutMe {
	color: black;
	font-size: 1em;
	width: calc(100vw - 17px);
	/* width: 100vw; */
	margin: 0 0 0 0;

	/* background: #d9e5d6; */
	min-height: 100vh;
	background: linear-gradient(
		90deg,
		rgb(36, 72, 102) 0%,
		rgba(70, 130, 180, 1) 20%,
		rgb(174, 205, 230) 50%,
		rgba(70, 130, 180, 1) 80%,
		rgb(36, 72, 102) 100%
	);
	/* box-sizing: border-box; */
}
.imageContainer {
	width: 80vw;
	/* position: relative; */
	margin: 40px auto;
	padding-top: 20px;
	padding-bottom: 120px;
	/* margin-top:15px; */
	/* background: linear-gradient(
		90deg,
		rgb(36, 72, 102) 0%,
		rgba(70, 130, 180, 1) 20%,
		rgb(174, 205, 230) 50%,
		rgba(70, 130, 180, 1) 80%,
		rgb(36, 72, 102) 100%
	); */
}

.imageContainer img {
	display: block;
	margin-left: auto;
	margin-right: auto;
	width: 50%;
	box-shadow: 5px 5px 20px rgba(18, 80, 107, 1);
	min-width: 350px;
	/* min-width: 350px; */
}
main {
	background: #d9e5d6;
	position: relative;
	box-sizing: border-box;
	margin: auto;
	margin-top: 50px;
	overflow: auto;
	width: 50%;
	min-width: 700px;
	top: -150px;
	box-shadow: 5px 5px 10px rgba(18, 80, 107, 1);
}
h3 {
}
article {
	margin: 10px;
	box-sizing: border-box;
	text-shadow: 2px 2px 6px rgba(174, 205, 230, 80%);

}

p {
	margin: 10px;
	text-shadow: 2px 2px 6px rgba(174, 205, 230, 80%);
}

@media (hover: none), (max-width: 900px) {
	.imageContainer {
		padding: 5px 0 5px 0;
		width: 100vw;
	}
	.imageContainer img {
		box-shadow: none;
		margin-left: auto;
		margin-right: auto;
		/* margin-top: 5px;
		margin-bottom: 5px; */
	}
	main {
		position: static;
		padding: 10px 10px 20px 10px;
		margin-top: 0px;
		margin-left: auto;
		margin-right: auto;
		box-shadow: none;
		width: 100vw;
		overflow: hidden;
		min-width: 350px;
	}
	.AboutMe {
		margin-bottom: 45px;
		width: 100vw;
	}
}
