p {
	margin: 5px;
	/* width: 90%; */
}
h2 {
	text-shadow: 1.5px 1.5px grey;
}
.codeButton {
	color: white;
}


.deploymentButton {
	color: white;
}

.button {
	border: solid black;
	background-color: #3e505b;
	border-radius: 10px;
	text-decoration: none;
	text-align: center;
	float: left;
	display: block;
	height: 20px;
	padding: 10px;
	margin: 10px;
	width: 80px;
	box-shadow: 5px 5px #4682b4;
}

.button:hover {
	transition: all ease-in 0.2s;
	transform: scale(1.1);
	box-shadow: 10px 10px 3px #4682b4;
}

.buttonContainer {
	width: 100%;
	animation-name: fadeIn;
	animation-duration: 3s;
}

.techTags{
	border: solid 1px black;
	border-radius: 4px;
	float: left;
	margin: 4px 5px;
	padding:3px;
	background: #cdcdcd;
	opacity: .7;
	list-style: none;
	font-weight: bold;
	box-shadow: 2px 2px grey;
}

.CardActive img {
	transition: all ease-in-out .5s;
	object-fit: contain;
	flex: 1;
	margin: auto;
	/* padding: 5px; */
	overflow: hidden;
	height: 200px;	
	min-width: 200px;
	min-height: 200px;
	width: 100%;
	height: auto;

}

.Card img {
	position: absolute;
	object-fit: fill;
	width: auto;
	height: 230px;
	max-width: 300px;
	transition: all ease-in-out .5s;
	top: 0;
	margin:auto;
	left:0;
	right:0;
	z-index: -1;
	opacity: .5;
	background-size: contain;
	margin-top: 70px;
}

.Card {
	border: solid black 1px;
	box-shadow: 5px 5px;
	width: 300px;
	height: 300px;
	padding: 10px;
	margin: 6px;
	text-align: left;
	border-radius: 1px;
	cursor: pointer;
	/* transition: width 0.25s; */
	position: relative;
	z-index: 0;
	background: linear-gradient(to bottom,#74B3CE 0%,#D9E5D6 40%);
	scroll-snap-align: start start;
}
.ImageBox{
	object-fit: contain;
	display: flexbox;
	flex-shrink: 1;
}

.CardActive {
	width: calc(100% - 30px);
	/* box-sizing: border-box; */
	border: solid black 2px;
	padding: 5px;
	margin-top: 5px;
	margin-bottom: 5px;
	text-align: left;
	border-radius: 5px;
	background: #74B3CE;
	/* transition: width ease-out .2s; */
	position: fixed;
	top: 50px;
	display: flex;
	flex-direction: column;
	z-index: 5;
	overflow: auto;
	left:0;
	right:0;
	margin-left:auto;
	margin-right:auto;
	outline: 9999px solid rgba(0, 0, 0, 0.5);
}

.Card .description {
	display: none;
}

.exitButton {
	width: 30px;
	height: 30px;
	position: absolute;
	right: 2px;
	top:2px;
	background: none;
	border: none;
	font-display: 10px;
}
.exitButton:hover{
	border: #233237 1px solid;
	border-radius: 5px;
	background:#D9E5D6;
	
}

.Card:hover {
	transition: all ease-in 0.2s;
	transform: scale(1.1);
	background:#D9E5D6;
}

.CardCollapsed {
	transform: width(20px);
}

.hidden{
	display: none;
}

@keyframes fadeIn {
	from {
		opacity: 0;
	}
	to {
		opacity: 1;
	}
}

@media (max-width: 730px) {
	.CardActive {
		margin-top: 50px;
		/* max-height: 80vh; */
	}
}

@media (max-width: 385px) {
	.CardActive {
		margin-top: 85px;
		/* max-height: 10rem; */
	}
}
@media (hover: none) {
	.Card:hover {
		transition: none;
		transform: none;
		
	}
	.CardActive{
		margin-top: 0px;
	}
	/* .exitButton {
		display: none;
	} */
}