.Headline{
    color:white;
    display: inline-block;
    text-align: center;
    /* float:left; */
    position: fixed;
    width:250px;
    top: 0px;
    z-index: 5;
    padding:0px;
}
.Headline:before{
    color:white;
    content: '{ Jason Forman }';
    font-size: 200%;
    animation-name:headline;
    animation-duration:8s;
    animation-iteration-count: infinite;
}


@keyframes headline {
    5% {opacity: 0;}
    10% {opacity: 1;content: '{ JSON Format  }';}
    15% {opacity: 0;}
    16% {content:'{ Jason Forman }'}
    25% {opacity: 1;}
}
@media(hover:none){
    .Headline{
        width: 100vw;
        background: rgba(18, 80, 107, 1);
        border-bottom: 1px solid black;
        /* background:rgba(80,137,145,1); */
        height:  45px;
        margin: auto;
    }
}